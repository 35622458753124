<template>
  <div>
    <el-dialog title="新帖子"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               :width="width"
               :before-close="handleClose">
      <el-form :model="form"
               ref="formRef"
               label-width="90px"
               :rules="rules">
        <el-row>
          <el-col :span="12">
            <el-form-item label="版块:"
                          prop="board_id">
              <el-select v-model="form.board_id"
                         style="width:100%"
                         placeholder="请选择">
                <el-option v-for="item in plateList"
                           :key="item.board_id"
                           :label="item.board_name"
                           :value="item.board_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="标题:"
                      prop="discuss_title">
          <el-input clearable
                    placeholder="请输入标题"
                    v-model="form.discuss_title" />
        </el-form-item>
        <el-form-item label="谁能看到:"
                      v-if="edu_school_id == 1"
                      prop="is_public">
          <el-select v-model="form.is_public"
                     style="width:100%"
                     placeholder="请选择">
            <el-option v-for="item in showList"
                       :key="item.id"
                       :label="item.label"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="内容:"
                      prop="discuss_content">
          <el-input clearable
                    placeholder="请输入内容"
                    v-model="form.discuss_content"
                    type="textarea"
                    :rows="5" />
        </el-form-item>
        <el-form-item label="图片:">
          <!-- 限制类型：image/bmp,image/jpeg,image/png,image/jpg,image/tiff,image/gif -->
          <div>
            最多可以上传5张图片，每张图片文件大小为5M
          </div>
          <el-row :gutter="10"
                  style="margin-top:15px">
            <el-col :span="4"
                    style="position:relative"
                    v-for="(item,index) in srcList"
                    :key="item">
              <el-image class="loadImage"
                        :src="item"
                        :preview-src-list="srcList">
              </el-image>
              <div class="delImage"
                   @click="delImage(index)">
                <img src="@/assets/icons/close.png"
                     alt="">
              </div>
            </el-col>
            <el-col :span="4"
                    v-if="srcList.length != 5">
              <div class="add"
                   @click="uploadImage">
                +
              </div>
            </el-col>
          </el-row>
        </el-form-item>
        <!-- 限制类型：video/mp4,video/3gpp,video/mpeg,video/ogg -->
        <!-- <el-form-item label="视频:">
          <div>
            最多可以上传1个视频文件，每个视频文件大小为20M
          </div>
          <el-button type="primary"
                     v-if="videoList.length != 1"
                     @click="uploadVideo">上传视频</el-button>
          <el-tag closable
                  v-else
                  @close="delTag">{{videoList[0].name}}</el-tag>
        </el-form-item> -->
      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary"
                   @click="postMessage">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { upload } from '@/api/upload.js'

export default {
  data () {
    return {
      form: {},
      plateList: [],
      rules: {
        board_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        discuss_title: [
          { required: true, message: '请输入', trigger: 'change' },
        ],
        discuss_content: [
          { required: true, message: '请输入', trigger: 'change' },
        ],
        is_public: [
          { required: true, message: '请输入', trigger: 'change' },
        ]
      },
      dialogVisible: false,
      width: '800px',
      srcList: [],
      fileList: [],
      videoList: [],
      progressList: [],
      edu_school_id: '',
      showList: [
        { id: 0, label: '公共' },
        { id: 1, label: '本校' },
      ]
    }
  },
  mounted () {
    if (document.body.clientWidth < 1200) {
      this.width = '700px'
    }
  },
  methods: {
    delTag () {
      this.videoList = []
    },
    delImage (index) {
      this.srcList.splice(index, 1)
      this.fileList.splice(index, 1)
    },
    handleClose () {
      this.srcList = []
      this.videoList = []
      this.fileList = []
      this.$refs.formRef.resetFields()
      this.dialogVisible = false
    },
    uploadImage () {
      this.$get_file({
        limit: 5,
        fileType: 'image/*',
      }).then(res => {
        this.fileList.push(res)
        this.srcList.push(window.webkitURL.createObjectURL(res))
      })
    },
    uploadVideo () {
      this.$get_file({
        limit: 20,
        fileType: 'video/*',
      }).then(res => {
        console.log('res', res.name);
        this.videoList = []
        this.videoList[0] = res
      })
    },
    postMessage () {
      this.$refs.formRef.validate(val => {
        if (!val) return
        // 上传文件
        var promiseAll = []
        this.fileList.forEach((item, index) => {
          var formData = new FormData();
          formData.append('discuss_image', item)
          formData.append('filename', 'discuss_image')
          var form = {
            filename: 'discuss_image',
          }
          formData.append('sign', this.$getMd5Form(form))
          promiseAll.push(upload(formData))

        });

        // 上传视频
        if (this.videoList.length) {
          var formData = new FormData();
          formData.append('discuss_video', this.videoList[0])
          formData.append('filename', 'discuss_video')
          var form = {
            filename: 'discuss_video',
            api_key
          }
          formData.append('sign', this.$getMd5Form(form))
          promiseAll.push(upload(formData))
        }
        this.form.discuss_image = []
        this.form.discuss_video = ''
        Promise.all(promiseAll).then(res => {
          if (this.videoList.length) {
            this.form.discuss_video = res[res.length - 1].data.info.thumb_path
            res.splice(res.length - 1, 1)
          }
          res.forEach(item => {
            this.form.discuss_image.push(item.data.info.thumb_path)
          });
          this.createTie()
        })

      })
    },
    createTie () {
      this.$http({
        url: '/api/v1/discuss/create',
        method: 'post',
        data: this.form
      }).then(() => {
        this.$notify({
          title: '提示',
          message: '帖子发表成功，等待审核!',
          type: 'success'
        });
        this.handleClose()
        this.$parent.refresh()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.add {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #808080;
  cursor: pointer;
  border: 2px dashed #d9d9d9;
  border-radius: 5px;
  &:hover {
    border: 2px dashed #409eff;
    color: #409eff;
  }
}
.loadImage {
  width: 100px;
  height: 100px;
  border-radius: 5px;
}
.delImage {
  cursor: pointer;
  position: absolute;
  color: white;
  background: #e0e0e0;
  opacity: 0.5;
  border-radius: 50%;
  top: 0;
  right: 13px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 24px;
    height: 24px;
  }
}
::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
</style>